




























































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';
import IconDialog from '../../elements/com-icon-dialog.vue';

/**
 * input的配置项
 */
@Component({
  name: 'ButtonConfig',
  components: {
    IconDialog
  }
})
export default class extends Mixins(ElePropertiesMixins) {
  private iconDialogVisible: boolean = false;
  handlerSelectIcon() {
    this.iconDialogVisible = true;
  }
}
