var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "button",
          expression: "widget.compType === 'button'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_button_text") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.text,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "text", $$v)
                },
                expression: "widget.properties.text",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.widget.properties.showLabel,
                expression: "widget.properties.showLabel",
              },
            ],
            attrs: { label: _vm.$t("lang_label_length") },
          },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.labelWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                },
                expression: "widget.properties.labelWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_button_type") } },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: _vm.$t("lang_please_select") },
                model: {
                  value: _vm.widget.properties.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "type", $$v)
                  },
                  expression: "widget.properties.type",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "primary", value: "primary" },
                }),
                _c("el-option", {
                  attrs: { label: "success", value: "success" },
                }),
                _c("el-option", {
                  attrs: { label: "warning", value: "warning" },
                }),
                _c("el-option", {
                  attrs: { label: "danger", value: "danger" },
                }),
                _c("el-option", { attrs: { label: "info", value: "info" } }),
                _c("el-option", { attrs: { label: "text", value: "text" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_button_size") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.size,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "size", $$v)
                  },
                  expression: "widget.properties.size",
                },
              },
              [
                _c("el-radio", { attrs: { label: "medium" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_large"))),
                ]),
                _c("el-radio", { attrs: { label: "small" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_in"))),
                ]),
                _c("el-radio", { attrs: { label: "mini" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_small"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_simple_style") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.plain,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "plain", $$v)
                },
                expression: "widget.properties.plain",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_fillet") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.round,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "round", $$v)
                },
                expression: "widget.properties.round",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_circular") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.circle,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "circle", $$v)
                },
                expression: "widget.properties.circle",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_icon") } },
          [
            _c(
              "el-input",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select_Icon"),
                  readonly: "",
                },
                model: {
                  value: _vm.widget.properties.icon,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "icon", $$v)
                  },
                  expression: "widget.properties.icon",
                },
              },
              [
                _c("template", { slot: "append" }, [
                  _c("i", {
                    staticClass: "el-icon-picture",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.handlerSelectIcon },
                  }),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
      _c("icon-dialog", {
        attrs: { visible: _vm.iconDialogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.iconDialogVisible = $event
          },
        },
        model: {
          value: _vm.widget.properties.icon,
          callback: function ($$v) {
            _vm.$set(_vm.widget.properties, "icon", $$v)
          },
          expression: "widget.properties.icon",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }