import { render, staticRenderFns } from "./button.vue?vue&type=template&id=af912396&"
import script from "./button.vue?vue&type=script&lang=ts&"
export * from "./button.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('af912396')) {
      api.createRecord('af912396', component.options)
    } else {
      api.reload('af912396', component.options)
    }
    module.hot.accept("./button.vue?vue&type=template&id=af912396&", function () {
      api.rerender('af912396', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form-design/config/elements/button.vue"
export default component.exports